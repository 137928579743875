import { $themeBreakpoints } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    password: null,
    windowWidth: 0,
    shallShowOverlay: false,
    language: "de",
    avatarImg: "",
    profileImg: "",
    hasInvoice: false,
    hasDocs: false,
    company:null,
    watchCompany:null,
    firstTask:false,
    countries:[],
    countries_code:[],
    fileBase64:'',
    fileType:'',
    sidebar:false,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    getFileUrl(){
      return state.fileBase64
    },
    getFileType(){
      return state.fileType
    }
  },
  mutations: {
    UPDATE_PASSWORD(state, val) {
      state.password = val
    },
    UPDATE_LANGUAGE(state, val) {
      state.language = val;
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    SET_COUNTRIES_LIST(state, array){
     state.countries = array
    },
    SET_COUNTRIES_CODE_LIST(state,array){
      state.countries_code = array
    },
    
    SET_COMPANY_INFO(state,company){

         state.company = company
    },
    COMPANY_INFO(state,company){
        state.watchCompany = company
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_AVATAR_HEADER(state, avatar) {
      state.avatarImg = avatar;
    },
    SET_PROFILE_HEADER(state, profile) {
      state.profileImg = profile;
    },
    CHECK_FOR_INVOICE(state, bool) {
      state.hasInvoice = bool;
    },
    CHECK_FOR_DOCS(state, bool) {
      state.hasDocs = bool;
    },
    FIRST_TASK_STATUS(state, bool){
      state.firstTask = bool
    },
    SET_FILE_BASE64(state, url){
        state.fileBase64 = url
    },
    SET_FILE_TYPE(state, url){
      state.fileType = url
    },
    ON_SIDEBAR_CLICK(state,sidebar){
      state.sidebar = sidebar
    }
  },
  actions: {},
};
