import multiguard from 'vue-router-multiguard';
import middleware from './middlewares/index'
export default  [

  {
    path: "/invoices",
    name: "invoices",
    component: () => import("../components/cookpit/Invoices.vue"),
    beforeEnter: multiguard([
      middleware.mustBeSeller,
      middleware.UserFullAccess
    ]),
    meta: {
      middleware: "auth",
    },
  },

  {
    path: "/invoice/:id/request/:invoice",
    name: "invoices",
    component: () => import("../components/invoices/Request.vue"),
    beforeEnter: multiguard([
      middleware.mustBeSeller,
      middleware.UserFullAccess
    ]),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/invoice/:id",
    name: "invoices",
    component: () => import("../components/invoices/InvoiceDetails"),
    beforeEnter: multiguard([
      middleware.mustBeSeller,
      middleware.UserFullAccess
    ]),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/invoices/add-new",
    name: "invoices",
    component: () => import("../components/invoices/AddNewInvoice"),
    beforeEnter: multiguard([
      middleware.mustBeSeller,
      middleware.UserFullAccess,
    ]),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/documents",
    name: "documents",
    component: () => import("../components/cookpit/UploadDocs.vue"),
    beforeEnter: multiguard([
      middleware.mustBeSeller,
      middleware.UserFullAccess,
    ]),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/referral-program",
    name: "referralProgram",
    component: () => import("../components/cookpit/ReferralProgram.vue"),
    beforeEnter: multiguard([
      middleware.mustBeSeller,
      middleware.UserFullAccess,
    ]),
    meta: {
      middleware: "auth",
    },
  },
];
