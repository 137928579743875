import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store/index'
// eslint-disable-next-line import/no-mutable-exports
const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    // baseURL: 'http://localhost:8000/api',
    // Production
    baseURL: 'https://api.aequitex.com/api',
    // baseURL: 'https://api-staging.sourcecode.training/api',

    //Staging
    // crossDomain: true,
    // baseURL: 'https://api-staging.aequitex.com/api',
    // timeout: 1000,run
    withCredentials: true,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        Authorization: `Bearer ${store.state.auth.token}`,
    },
});
Vue.prototype.$http = axiosIns;

export default axiosIns
