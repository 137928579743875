import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    showInvoice:false,
    showDocs:false,
    lightMode:'light',
    linkSideBar: null,
  },
  getters: {
    getLinkSideBar : (state) => {state.linkSideBar},
    showInvoiceGetters : (state) => {state.showInvoice},
    showDocsGetters : (state) => {state.showDocs},
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    SHOW_INVOCIES_SIDE_BAR(state,bool){
      state.showInvoice = bool
    },
    SHOW_DOCS_SIDE_BAR(state,bool){
      state.showDocs = bool
    },
    CHANGE_LIGHT_MODE(state,color){
      state.lightMode = color
    },
    SHOW_TABLES(state,link){
      state.linkSideBar = link
    }
  },
  actions: {},
}
